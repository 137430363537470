.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  width: 25%;
  margin: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.csv_file_name {
  padding-left: 1rem;
  font-weight: bold;
}

.parse_csv_btn {
  margin: 1rem 0;
}

.parse_csv_btn:first-child {
  margin-right: 1rem;
}

.username-cont {
  margin-top: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
  width: 100% !important;
}

.login_btn_span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-decoration: underline;
  display: block;
  width: fit-content;
  margin: 10px auto 0;
  cursor: pointer;
}

.input {
  padding: 0.35rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  border: 1px solid #ccc;
  margin: 0;
}

.input:focus {
  outline: var(--color-light-grey);
  /* background-color: #ccc; */
}

.login-btn {
  margin-top: 0.75rem;
  padding: 0.35rem 0;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
}

.login-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.error {
  color: var(--color-dark-red);
  background-color: var(--color-light-red);
  padding: 0.35rem 0.75rem;
  margin: 0.5rem 0 1rem;
  border-radius: 0.35rem;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-black);
  font-weight: 600;
  padding: 0.5rem 0.75rem;
}

.link:hover {
  opacity: 0.8;
}

.dashboardpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardpage > * {
  margin-bottom: 0.75rem;
}

.top-left-link {
  position: absolute;
  top: -20%;
  left: 20%;
}

.create-pro-btn {
  position: relative;
}

.project-link {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.lead-list_item {
  text-decoration: none;
}

.oc_eyeicon_input {
  position: relative;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .oc_eyeicon_input
  input,
.login_input_row .oc_eyeicon_input input {
  padding-right: 40px;
}

.zu_password_eye_btn {
  position: absolute;
  right: 14px;
  top: 15px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  z-index: 99;
  cursor: pointer;
}
.zu_password_eye_btn svg {
  width: 18px;
  height: 18px;
}

.icon_in_input_field {
  position: absolute;
  right: 20px;
  top: 9px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  z-index: 99;
}
